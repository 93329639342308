<template >

    <div class="content">
      <div class="info">
        <p>Concevez une expérience web exceptionnelle avec mes services de développement de Wordpress, Odoo ou en développant "from scratch" Vuejs, React.<br/>
          <span style="font-style: italic;"> Expertise Technique:</span> Un développeur passionné par des technologies modernes.<br/>
          <span style="font-style: italic;">Approche Personnalisée:</span> Chaque projet est unique, tout comme mon engagement pour votre succès.<br/>
          <span style="font-style: italic;">Innovation Constante:</span> Suivez les dernières tendances et assurez-vous d'avoir une présence en ligne. Contactez-moi dès aujourd'hui pour discuter de la manière dont je peux transformer vos idées en une expérience exceptionnelle 😁</p>
        <router-link to="/contactme">Demander un devis gratuit</router-link>
      </div>

      <div class="swiper-info">
        <swiper
            :effect="'cards'"
            :grabCursor="true"
            :modules="modules"
            class="mySwiper"
        >
          <swiper-slide>
            <span class="numero">1</span>
            <h2>Conception de site web</h2>
            <ul>
              <li>Création de l’arborescence et de la navigation de votre site web</li>
              <li> Intégration et mise en page des contenus</li>
              <li> Adaptation du design à votre image</li>
              <li> Adaptation du site aux mobiles et tablettes</li>
            </ul>

          </swiper-slide>
          <swiper-slide>
            <span class="numero">2</span>
            <h2>Hébergement</h2>
            <ul>
              <li>Conseils dans le choix du domaine et de l’extension</li>
              <li>Configuration de l’hébergement</li>
              <li>Configuration des adresses e-mail</li>
              <li>Sécurisation du site web (SSL)</li>
              <li>Vous êtes propriétaire de votre nom de domaine et de votre site</li>
            </ul>
          </swiper-slide>
          <swiper-slide>
            <span class="numero">3</span>
            <h2>Création de contenu</h2>
            <ul>
              <li>Rédaction de textes</li>
              <li>Création de visuels</li>
              </ul>


          </swiper-slide>
          <swiper-slide>
            <span class="numero">4</span>
            <h2>Maintenance</h2>
            <ul>
              <li>Conseils dans la construction de votre site web</li>
              <li>Rédaction d’un cahier des charges avec vos exigences</li>
              <li>Rédaction des mentions légales (obligatoires pour tous professionnels)</li>
              <li>Planification du déroulement de votre projet</li>
            </ul>
          </swiper-slide>
          <swiper-slide>
            <span class="numero">5</span>
            <h2>SEO</h2>
            <ul>
              <li>Optimisation  pour les moteurs de recherche</li>
              <li>Création de contenu optimisé pour un référencement naturel</li>
              <li>Indexation manuelle auprès de <span class="google-text">Google</span></li>
            </ul>
          </swiper-slide>
          <swiper-slide>
            <span class="numero">6</span>
            <h2>Accompagnement</h2>
            <ul>
              <li>Formation à la création de contenu en autonomie</li>
              <li>Conseils pour l’animation de votre site web</li>
              <li>Conseils pour l’animation de vos réseaux sociaux</li>
            </ul>

          </swiper-slide>
        </swiper>
      </div>
    </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-cards';


// import required modules
import { EffectCards } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCards],
    };
  },
  data() {
    return {
      swiper: null,
    };
  },
};
</script>
<style>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  min-height: 80vh;
  background: rgb(0, 0, 0, 0.9);;

}
.info {
  display:flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  max-width: 450px;
  padding: 0 35px;
  text-align: justify;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.info a {
  display: block;
  padding: 10px 40px;
  margin: 10px auto;
  font-size: 1.1rem;
  font-weight: 700;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  color: #784ba0;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.info p {
  font-size: 1.1rem;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 20px;
}
.swiper {
  width: 360px;
  height: 480px;
}
.swiper-slide {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 18px;
  font-size: 22px;
  color: #fff;
  background-image: linear-gradient(to right bottom, #5e95e8, #4985eb, #3874ec, #3161ea, #364ce6);
}
.swiper-slide ul {
  list-style: none;
  justify-content: space-between;
}

.swiper-slide li {
  justify-content: space-between;
  font-size: 1rem;
  line-height: 1.4;
  color: #fff;
  position: relative;
  text-align: center;
  padding: 10px 20px 10px 20px;
  margin: 0px 20px 10px 20px;
  border: solid 1px rgba(255, 255, 255, 0.2);
  border-radius: 20px;
}

/*

.swiper-slide:nth-child(6n) {
  background-color: #050A30;
}

.swiper-slide:nth-child(5n) {
  background-color: #091548
}

.swiper-slide:nth-child(4n) {
  background-color: #0E1E60;
}

.swiper-slide:nth-child(3n) {
  background-color: #132678;
}

.swiper-slide:nth-child(2n) {
  background-color: #173090;
}

.swiper-slide:nth-child(1n) {
  background-color: #1B369F;
}
*/

.numero{
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  padding: 7px 18px;
  margin: 10px;
  border-radius: 20px;
  letter-spacing: 2px;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: "Kode Mono";
  background: rgba(255, 255, 255, 0.095);
  box-shadow: inset 2px -2px 20px rgba(214, 214, 214, 0.2), inset -3px 3px 3px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(74px);
}
.swiper-slide h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  font-family: "Kode Mono";
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4;
  margin: 0 0 20px 20px;
}

.google-text {
  color: #fff;
  background: linear-gradient(to right, #4285F4, #DB4437, #F4B400,#4285F4, #0F9D58, #DB4437);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 2s linear infinite;
}
@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .info {
    border-right: none;
  }

}
</style>
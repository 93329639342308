import { createRouter,createWebHistory } from 'vue-router';
import Home from "../components/AppHome.vue";
import AppPrestations from "@/components/AppPrestations.vue";
import AppMeContacter from "@/components/AppMeContacter.vue";
import AppErrorNotFound from "@/components/AppErrorNotFound.vue";

const routes =
    [
        { path: '/', component: Home },
        { path: '/prestations', component: AppPrestations},
        { path: '/contactme', component: AppMeContacter},
        { path: '/:notFound(.*)', component: AppErrorNotFound}

    ];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;

<script setup>

</script>

<template>
  <div class="realisations">
    <h1>REALISATIONS</h1>
    <p> Je présenterais bientôt mes dernières réalisations ici :)</p>
  </div>
</template>

<style scoped>
.realisations {
  justify-content: center;
  border-radius: 30px;
  background-color:  rgb(0, 0, 0, 0.9);
  margin: 20px;
}
.realisations h1 {
  color: var(--main-theme-color);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Kode Mono";
}
.realisations p {
  color: white;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Kode Mono";
}

</style>
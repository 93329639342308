<template>
  <AppHeader />
  <router-view />
</template>

<script>
import AppHeader from './components/AppHeader.vue'


export default {
  name: 'App',
  components: {
    AppHeader,
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*min-height: 800px;*/
  height: 100%;
  --main-theme-color: #00bcd4;
  --main-background-color: #1a1a1a;
  color: white;
  background-color: #1a1a1a;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/main_background.jpg");
  font-family: "Roboto Thin", sans-serif;
}
* {
  margin: 0;
  padding: 0;

}
body {
  margin: 0;
  padding: 0;
  background-color: #1a1a1a;

}
</style>

<template>
  <div class="background">
    <div class="container">
      <div class="screen">
        <div class="screen-header">
          <div class="screen-header-left">
            <div class="screen-header-button close"></div>
            <div class="screen-header-button maximize"></div>
            <div class="screen-header-button minimize"></div>
          </div>
          <div class="screen-header-right">
            <div class="screen-header-ellipsis"></div>
            <div class="screen-header-ellipsis"></div>
            <div class="screen-header-ellipsis"></div>
          </div>
        </div>
        <div class="screen-body">
          <div class="screen-body-item left">
            <div class="app-title">
              <span>ME</span>
              <span>CONTACTER</span>
            </div>
            <div class="illustration">
              <img src="../assets/message.svg" alt="contact">
            </div>
            <div class="app-contact">APPELLEZ-MOI : +33 7 73 12 05 00</div>
          </div>
          <div class="screen-body-item">
            <form action="https://formspree.io/f/xpzvdgyq" method="POST">
              <div class="app-form">
                <div class="app-form-group">
                  <input class="app-form-control" name="name" placeholder="NOM" required>
                </div>
                <div class="app-form-group">
                  <input class="app-form-control" name="email" type="email" placeholder="EMAIL" required>
                </div>
                <div class="app-form-group">
                  <input class="app-form-control" name="phone" placeholder="TELEPHONE">
                </div>
                <div class="app-form-group message">
                  <textarea class="app-form-control" name="message" placeholder="MESSAGE" required></textarea>
                </div>
                <div class="app-form-group buttons">
                  <button type="submit" class="app-form-button" >ENVOYER</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>

  <ul class="wrapper">
    <li class="icon linkedin">
      <span class="tooltip">Linkedin</span>
      <span><a href="https://www.linkedin.com/in/francoisxavierdesaintjean/" target="_blank"><img class="social-icon" src="../assets/linkedin.png" height="100" width="100" alt="linkedin"></a></span>
    </li>
    <li class="icon github">
      <span class="tooltip">Github</span>
      <span><a href="https://github.com/fixdsj" target="_blank"><img class="social-icon" src="../assets/github.png" height="100" width="100" alt="github"></a></span>
    </li>
  </ul>

</template>

<script>

</script>

<style scoped>
body {
  background: linear-gradient(to right, #ea1d6f 0%, #eb466b 100%);
  font-size: 12px;
}

body, button, input,textarea {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1.4px;
}

.background {
  display: flex;
  min-height: 70vh;
}

.container {
  flex: 0 1 700px;
  margin: auto;
  padding: 10px;
}

.screen {
  position: relative;
  background: #3e3e3e;
  border-radius: 15px;
}

.screen:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
  z-index: -1;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #ed1c6f;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  flex: 1;
  padding: 50px;
}

.screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "Kode Mono";
  color: var(--main-theme-color);
  font-size: 26px;
}

.illustration {
  margin-top: 40px;
  text-align: center;
  img {
    width: 5em;
  }

}
.app-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  background: var(--main-theme-color);
}

.app-contact {
  margin-top: auto;
  font-size: 8px;
  color: #888;
}

.app-form-group {
  margin-bottom: 15px;
}

.app-form-group.message {
  margin-top: 40px;
}

.app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  color: #ddd;
  font-size: 14px;
  outline: none;
  transition: border-color .2s;
}

.app-form-control::placeholder {
  color: #666;
}

.app-form-control:focus {
  border-bottom-color: #ddd;
}

.app-form-button {
  background: none;
  border: none;
  color: var(--main-theme-color);
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.app-form-button:hover {
  color: var(--main-theme-color);
}


@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }

  .screen-body-item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 12px;
  }

  .app-title:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }

  .screen-body-item {
    padding: 0;
  }
}


.wrapper {
  display: inline-flex;
  list-style: none;
}

.wrapper .icon {
  position: relative;
  background: var(--main-theme-color);;
  border-radius: 50%;
  padding: 15px;
  margin: 0px 10px  10px 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: var(--main-theme-color);;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background:var(--main-theme-color);
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}



.wrapper .linkedin:hover,
.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip::before {
  background: #1877F2;
  color: #ffffff;
}





.wrapper .github:hover,
.wrapper .github:hover .tooltip,
.wrapper .github:hover .tooltip::before {
  background: #333333;
  color: #ffffff;
}


.social-icon {
  width: 30px;
  height: 30px;
}


</style>

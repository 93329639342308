<script >
export default {
  name: 'AppHeader',
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
}
</script>

<template>
  <div class="header-container">
    <div class="title-container">
      <router-link to="/">
      <h1 class="hoverCSS3">François-Xavier de Saint Jean</h1>
      </router-link>
    </div>
    <div class="menu-container" :class="{ 'openmenu': showMenu }">
      <router-link to="/" :class="{ 'active-tab': $route.path === '/' }">Accueil</router-link>
      <router-link to="/prestations" :class="{ 'active-tab': $route.path === '/prestations' }">Prestations</router-link>
      <router-link to="/contactme" :class="{ 'active-tab': $route.path === '/contactme' }">Contact</router-link>
      <a href="https://www.linkedin.com/in/francoisxavierdesaintjean/" target="_blank">LinkedIn</a>
    </div>
    <div class="menu-responsive" @click="toggleMenu" :class="{ 'hidden': showMenu }">☰</div>
  </div>
</template>

<style scoped>
.header-container {
  display: flex;
  background: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);
  font-family: "Kode Mono";
}
.title-container {
  padding: 10px;
}
.title-container h1 {
  color: white;
  font-size: 1.5em;
}
.title-container a {
  text-decoration: none;
}
.menu-container {
  margin-left: auto;
  display: flex;
  padding: 10px;
}
.menu-container a {
  padding: 10px;
  color: white;
  text-decoration: none;
}
.active-tab {
  border-bottom: solid 2px var(--main-theme-color)
}

.hoverCSS3{
  display: inline-block;
  position: relative;
  padding-bottom:10px;
  font-size: 30px;
}
.hoverCSS3:after{
  content: "";
  position: absolute;
  background-color: rgb(150,150,150);
  height: 3px;
  width: 0;
  top: 100%;
  left: 0;
  display: inline-block;
  opacity: 0;

  -webkit-transition: opacity .4s ease-out, width .4s ease-out  ;
  -moz-transition: opacity .4s ease-out, width .4s ease-out ;
  -o-transition: opacity .4s ease-out, width .4s ease-out ;
  transition: opacity .4s ease-out, width .4s ease-out;
}

.hoverCSS3:before{
  content: "*";
  width: 22px;
  height: 22px;
  display: inline;
  float: right;
  margin-left: 8px;
  margin-top: 8px;
  background: white;
  color: black;
  border-radius: 4px;

  -webkit-transition:  -webkit-transform .4s ease-out;
  -moz-transition: transform .4s ease-out;
  -o-transition:  transform .4s ease-out ;
  transition:transform .4s ease-out, -ms-transform .4s ease-out;
}

.hoverCSS3:hover:before{
  transform:rotate(180deg);
  -ms-transform:rotate(180deg); /* IE 9 */
  -webkit-transform:rotate(180deg); /* Opera, Chrome, and Safari */
}

.hoverCSS3:hover:after{
  opacity: 1;
  width: 100%;
}
.menu-responsive {
  display: none;
}

@media (max-width: 768px) {

  .menu-container  {
    display: none;
  }
  .menu-responsive {
    display: block;
    padding: 10px;
    color: white;
    font-size: 1.5em;
  }
  .menu-responsive:hover {
    cursor: pointer;
  }
  .menu-responsive:after {
    content: "Menu";
  }
  .menu-responsive.active {
    display: block;
  }
  .menu-responsive.active:after {
    content: "Fermer";
  }
  .openmenu {
    display: flex;
    flex-direction: column;
  }

}

</style>